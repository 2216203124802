export default class DfCategory {
  private _identifier: string;
  private _name: string;
  private _selected = false;

  constructor(identifier: string, name: string) {
    this._identifier = identifier;
    this._name = name;
  }

  get identifier(): string {
    return this._identifier;
  }

  set identifier(identifier: string) {
    this._identifier = identifier;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get selected(): boolean {
    return this._selected;
  }

  set selected(selected: boolean) {
    this._selected = selected;
  }
}
